<template>
  <div>
    <a-form-model :model="form">
      <a-row :gutter="16">
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="dateRange">
            <a-range-picker v-model="form.dateRange" :allow-clear="false" :disabled-date="disabledDate" format="YYYY-MM-DD"></a-range-picker>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="project_id">
            <project-select v-model="form.project_id"></project-select>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-row :gutter="16">
      <a-col v-for="countItem in countItems" :key="`${countItem.key}_count`" :xs="24" :xl="8" style="margin-bottom: 16px;">
        <a-card
          :body-style="{ padding: '0 24px 24px 24px' }"
          :bordered="false"
          :head-style="{ border: 'none' }"
          :hoverable="true"
          :title="countItem.title"
        >
          <alert-event-count-chart
            :count-by="countItem.key"
            :date-range="form.dateRange"
            :project-id="form.project_id"
            style="height: 240px;"
          ></alert-event-count-chart>
        </a-card>
      </a-col>
    </a-row>

    <a-row :gutter="16">
      <a-col v-for="trendItem in trendItems" :key="`${trendItem.key}_trend`" :xs="24" :xl="12" style="margin-bottom: 16px;">
        <a-card
          :body-style="{ padding: '0 24px 24px 24px' }"
          :bordered="false"
          :head-style="{ border: 'none' }"
          :hoverable="true"
          :title="trendItem.title"
        >
          <alert-event-trend
            :date-range="form.dateRange"
            :project-id="form.project_id"
            :trend-by="trendItem.key"
            style="height: 320px;"
          ></alert-event-trend>
        </a-card>
      </a-col>
    </a-row>

    <a-row :gutter="16">
      <a-col v-for="topItem in topItems" :key="`${topItem.key}_top`" :xs="24" :xl="12" style="margin-bottom: 16px;">
        <a-card
          :body-style="{ padding: '0 24px 24px 0' }"
          :bordered="false"
          :head-style="{ border: 'none' }"
          :hoverable="true"
          :title="`${topItem.title} Top 10`"
        >
          <alert-event-top
            :date-range="form.dateRange"
            :project-id="form.project_id"
            :title="topItem.title"
            :top-by="topItem.key"
          ></alert-event-top>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment'
import AlertEventCountChart from '@/components/chart/AlertEventCountChart'
import ProjectSelect from '@/components/select/ProjectSelect'
import AlertEventTrend from './modules/AlertEventTrend'
import AlertEventTop from './modules/AlertEventTop'

export default {
  name: 'AlertStatistic',
  components: {
    AlertEventCountChart,
    AlertEventTop,
    AlertEventTrend,
    ProjectSelect
  },
  data () {
    return {
      form: {
        dateRange: [moment().subtract(15, 'day'), moment()]
      },
      countItems: [
        { key: 'severity', title: '告警事件严重性' },
        { key: 'source_type', title: '告警事件源' },
        { key: 'category', title: '告警事件类型' }
      ],
      trendItems: [
        { key: 'severity', title: '告警事件严重性趋势' },
        { key: 'source_type', title: '告警事件源趋势' }
      ],
      topItems: [
        { key: 'name', title: '告警事件名称' },
        { key: 'source', title: '告警事件源' }
      ]
    }
  },
  methods: {
    disabledDate (date) {
      return date && date > moment().endOf('day')
    }
  }
}
</script>
