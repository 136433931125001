<template>
  <a-tabs :active-key="activeKey" tab-position="left" @change="key => { this.activeKey = key }">
    <a-tab-pane v-for="sourceType in sourceTypes" :key="sourceType" :tab="$t(`source_type.${sourceType}`)">
      <a-table
        :columns="columns"
        :data-source="dataSource"
        :loading="loading"
        :pagination="false"
        size="small"
        row-key="name"
      >
        <template slot="name" slot-scope="record">
          <a-tooltip placement="topLeft" :title="record">{{ record }}</a-tooltip>
        </template>
      </a-table>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import { getAlertEventTop } from '@/api/alert-event'

export default {
  name: 'AlertEventTop',
  props: {
    title: { type: String, default: '名称' },
    dateRange: { type: Array, required: true },
    projectId: { type: String },
    topBy: { type: String, required: true }
  },
  data () {
    return {
      activeKey: 'host',
      sourceTypes: ['host', 'middleware', 'database', 'web'],
      columns: [
        { dataIndex: 'name', title: this.title, ellipsis: true, scopedSlots: { customRender: 'name' } },
        { dataIndex: 'value', title: '统计', width: 100, fixed: 'right' }
      ],
      dataSource: [],
      total: 0,
      loading: false
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      const params = { source_type: this.activeKey, top_by: this.topBy }
      if (this.dateRange.length !== 0) {
        params.date_from = this.dateRange[0].format('YYYY-MM-DD')
        params.date_to = this.dateRange[1].format('YYYY-MM-DD')
      }
      if (this.projectId) params.project_id = this.projectId
      if (this.sourceType) params.source_type = this.sourceType
      this.loading = true
      getAlertEventTop(params).then(res => {
        const data = res.data
        this.dataSource = data.data
        this.total = data.total
      }).finally(() => {
        this.loading = false
      })
    }
  },
  watch: {
    dateRange () {
      this.fetch()
    },
    projectId () {
      this.fetch()
    },
    activeKey () {
      this.fetch()
    }
  }
}
</script>
